import React from "react";
import { Paper } from "./PricingLists";
import { Maybe, SanityButton, SanityLink } from "@graphql-types";
import styled from "styled-components";
import { Button } from "./Button";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";

const CardSection = styled("div")<{}>`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
`;

const ResponsiveHeader = styled("h3")<{}>`
  font-size: 26px;

  @media (max-width: 1340px) and (min-width: ${MOBILE_BREAKPOINT + 1}px) {
    font-size: 18px;
  }
`;

interface CardProps {
  title: string;
  description: string;
  button: SanityLink;
  image: JSX.Element;
  showBoxShadow: boolean;
}

const Card = ({
  title,
  description,
  button,
  image,
  showBoxShadow,
}: CardProps) => {
  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        boxSizing: "border-box",
        borderRadius: "10px",
      }}
      showBoxShadow={showBoxShadow}
    >
      {image}
      <CardSection>
        <ResponsiveHeader>{title}</ResponsiveHeader>
        <p>{description}</p>
        <div
          style={{
            bottom: "0px",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <Button {...button} isPink={false} linkText={button.linktext ?? ""} />
        </div>
      </CardSection>
    </Paper>
  );
};

type CardType = typeof Card;

export { Card };
export type { CardProps, CardType };
