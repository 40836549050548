import React from "react";
import { Section } from "./FeatureImageList";
import { Maybe, SanityEnterpriseFeatureList } from "@graphql-types";
import { Button } from "./Button";
import { CardGrid } from "./CardGrid";
import { Card } from "./Card";
import { FaFutbol } from "react-icons/fa";
import { colors } from "@util/constants";
import { Paper } from "./PricingLists";
import Image from "@components/shared/sub/image";
import styled from "styled-components";

interface EnterpriseFeatureListProps {
  featureList: Maybe<SanityEnterpriseFeatureList> | undefined;
}

const ImageWrapper = styled("div")``;

const EnterpriseFeatureList = ({ featureList }: EnterpriseFeatureListProps) => {
  if (!featureList) throw new Error("Feature List does not exist");

  return (
    <Section isBlue={false}>
      <h2 style={{ textAlign: "center" }}>{featureList.title}</h2>
      <p style={{ textAlign: "center", maxWidth: "800px", width: "80%" }}>
        {featureList.description}
      </p>
      <div style={{ width: "300px", paddingBottom: "20px" }}>
        <Button
          isPink
          {...featureList.Button}
          url={featureList.Button?.linkUrl}
          linkStyle={""}
        />
      </div>
      <Paper
        style={{
          backgroundColor: "#FFF0",
          boxShadow: "none",
          width: "fit-content",
        }}
        isBlue={false}
        showBoxShadow={true}
      >
        <CardGrid
          gridItems={featureList.enterpriseFeatureList!.map(li => (
            <Card
              title={li?.title ?? ""}
              description={li?.description ?? ""}
              image={
                !!li?.image ? (
                  <ImageWrapper>
                    <Image data={li.image!} height="50px" width="50px" />
                  </ImageWrapper>
                ) : (
                  <FaFutbol
                    style={{
                      minWidth: "50px",
                      minHeight: "50px",
                      color: colors.lightBlue,
                    }}
                  />
                )
              }
              button={li?.link!}
              showBoxShadow={true}
            />
          ))}
        />
      </Paper>
    </Section>
  );
};

export { EnterpriseFeatureList };
export type { EnterpriseFeatureListProps };
