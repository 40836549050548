import { Maybe } from "@graphql-types";
import { colors } from "@util/constants";
import { Progress } from "antd";
import React from "react";
import styled from "styled-components";

const CeloButton = styled("a")<{ isPink: boolean }>`
display: flex;
place-content: center;
place-items: center;

${({ isPink }) =>
  isPink
    ? `background-color: ${colors.pink};`
    : `background-color: ${colors.lightBlue};`};
line-height: 23px;
text-align: center;
font-family: "header bold";
border-radius: 20px;
padding: 8.5px 0px;
width: 100%;
color: ${colors.white};
opacity: 1;

}
transform: scale(1);
&:hover {
  color: white;
  transform: scale(1.05);
}

transition: all 0.2s;
`;

interface ButtonProps {
  linkText?: string | null | undefined;
  img?: string | null | undefined;
  url?: string | null | undefined;
  action?: (() => void) | null | undefined;
  isInternal?: boolean | null;
  isPink: boolean;
  linkStyle?: Maybe<string> | undefined;
  id?: string;
}

const Button = ({
  linkText,
  img,
  url,
  action,
  isInternal,
  isPink,
  linkStyle,
  id
}: ButtonProps): JSX.Element => {
  if (!!action) return <button></button>;

  if (!!url || !!linkText) {
    return linkStyle === "link" ? (
      <a id={id} href={url!}>{linkText}</a>
    ) : (
      <CeloButton id={id} isPink={isPink} href={url?.toString()}>
        {linkText}
      </CeloButton>
    );
  }

  return <div />;
};

export { Button };
export type { ButtonProps };
