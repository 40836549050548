import { Maybe, SanityPricePlan } from "@graphql-types";
import { MAX_WIDTH, MOBILE_BREAKPOINT } from "@util/constants";
import React from "react";
import styled from "styled-components";
import { PricingList } from "./PricingList";

export const Paper = styled("div")<{
  isBlue?: boolean;
  showBoxShadow?: boolean;
}>`
  background-color: "#fff";
  width: 80%;
  padding: 20px;
  position: relative;
  box-shadow: ${({ showBoxShadow }) =>
    showBoxShadow ? "0 2px 10px 0 rgba(0, 0, 0, 0.3)" : ""};
  max-width: ${MAX_WIDTH};
`;

//TODO: Move this definition somewhere else
export const Grid = styled("div")<{ numCols: number; gap?: number }>`
  display: grid;

  grid-template-columns: repeat(${({ numCols }) => numCols}, minmax(0, 1fr));
  grid-template-rows: 1fr;
  ${({ gap }) => `gap: ${gap ?? 10}px;`}
  width: fit-content;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    spacing: 5px;
  }
`;

const PricingGrid = styled(Grid)<{ numCols: number; gap?: number }>`
  & > *:nth-child(n + 5) {
    background-color: white;
  }
`;

interface PricingListsProps {
  sanityPricingLists: Maybe<Maybe<SanityPricePlan>[]> | undefined;
}

const PricingLists = ({ sanityPricingLists }: PricingListsProps) => {
  if (!sanityPricingLists)
    return (
      <div>
        <p>Pricing lists could not be retrieved!</p>
      </div>
    );

  const cellCount =
    sanityPricingLists
      .map(spl => spl?.featureList?.length)
      .reduce((prev, next) => Math.max(prev ?? 0, next ?? 0)) ?? 0;

  return (
    <Paper>
      <PricingGrid numCols={3} gap={0} style={{ columnGap: "50px" }}>
        {sanityPricingLists.map((spl, ind) => (
          <PricingList
            pricingList={spl}
            column={ind + 1}
            isPink={ind === sanityPricingLists.length - 1}
            cellCount={cellCount}
          />
        ))}
      </PricingGrid>
    </Paper>
  );
};

export { PricingLists };
export type { PricingListsProps };
