import React from "react"
import { Grid, Paper } from "./PricingLists"
import { Maybe, SanityImageCard } from "@graphql-types"
import { ImageItem } from "./ImageItem"
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants"
import styled from "styled-components"

const ResponsiveGrid = styled(Grid) <{}>
`
    gap: 10px;
    row-gap: 0;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    
    @media (max-width: ${TABLET_BREAKPOINT}px) and (min-width: ${MOBILE_BREAKPOINT + 1}px)
    {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 5px;
    }
    
    @media (max-width: ${MOBILE_BREAKPOINT}px) 
    {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        spacing: 5px;
    }
`

interface ImageListProps {
    imageItems: Maybe<Maybe<SanityImageCard>[]> | undefined
}

const ImageList = ({ imageItems }: ImageListProps) => {
    if (!imageItems) return <div>
        <p>Could not retrieve image items!</p>
    </div>

    return <Paper style={{backgroundColor: '#FFF0', boxShadow: 'none', width: 'fit-content'}}>
        <ResponsiveGrid numCols={4}>
            {imageItems?.map((imageItem, ind) => <ImageItem imageItem={imageItem} column={ind + 1} />)}
        </ResponsiveGrid>
    </Paper>
}

export { ImageList }
export type { ImageListProps }