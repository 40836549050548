import React from "react"
import { Grid } from "./PricingLists"
import styled from "styled-components";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";

interface CardGridProps {
    gridItems: JSX.Element[];
}

const TabletResponsiveGrid = styled(Grid) <{}>
`
    gap: 20px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    width: fit-content;
    
    @media (max-width: ${TABLET_BREAKPOINT}px) and (min-width: ${MOBILE_BREAKPOINT + 1}px)
    {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
    }
    
    @media (max-width: ${MOBILE_BREAKPOINT}px) 
    {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 20px;
    }
`


const CardGrid = ({ gridItems }: CardGridProps) => {
    return <TabletResponsiveGrid numCols={3}>
        {gridItems}
    </TabletResponsiveGrid>
}

export { CardGrid }
export type { CardGridProps }