import { Maybe, SanityImageCardList } from "@graphql-types";
import React from "react";
import styled from "styled-components";
import { ImageList } from "./ImageList";
import { MAX_WIDTH } from "@util/constants";

export const Section = styled('div') <{ isBlue?: boolean }>
    `
    display: flex;
    flex-direction: column;
    place-items: center;
    max-width: ${`${MAX_WIDTH}px`};
    width: 80%;
`

interface FeatureImageListProps {
    imageList: Maybe<SanityImageCardList> | undefined;
}

const FeatureImageList = ({ imageList }: FeatureImageListProps) => {
    if (!imageList) throw new Error("Could not load image list");
    return <Section isBlue={false}>
        <h2 style={{ textAlign: 'center' }}>{imageList.title}</h2>
        <p style={{ textAlign: 'center', width: '80%' }}>{imageList.description}</p>
        <ImageList imageItems={imageList.imageCards} />
    </Section>
}

export { FeatureImageList };
export type { FeatureImageListProps };