import React, { useEffect } from "react";
import { graphql, PageProps } from "gatsby";

import Hero from "@components/shared/hero";
import { Container, GiveMeSomeSpace } from "@util/standard";
import Cta from "@components/shared/cta";
import SEO from "@components/shared/seo";
import LayOut from "@components/layout";
import { Query, SanityFeaturesPageConnection } from "@graphql-types";
import { useRegionChanged } from "@util/hooks";
import { PricingLists } from "@components-v3/PricingLists";
import { FeatureImageList } from "@components-v3/FeatureImageList";
import { EnterpriseFeatureList } from "@components-v3/EnterpriseFeatureList";
import { MAX_WIDTH } from "@util/constants";
import styled from "styled-components";

const PlansAnchorContainer = styled("div")`
  position: relative;
`;

// The 95px offset is so that the top header does not overlap the plans area header when it is
const PlansAnchor = styled("div")`
  position: absolute;
  top: -95px;
`;

interface Data extends Query {
  altRegions: SanityFeaturesPageConnection;
}

interface Props extends PageProps {
  data: Data;
  pageContext: any;
}

const Features = (props: Props) => {
  const sanityFeaturesPage = props.data.allSanityFeaturesPage.nodes[0];
  const alternateRegions = props.data.altRegions.nodes.map(alt => alt.region);

  useRegionChanged("features");

  useEffect(() => {
    if (window.location.hash === "#plans") return;
    window.scrollTo(0, 0);
  }, []);

  const {
    featuressHero,
    featureImageList,
    enterpriseFeatureList,
    pricingLists,
    ctaBlock,
  } = sanityFeaturesPage;

  return (
    <>
      <SEO
        seoData={sanityFeaturesPage.seo}
        alternateRegions={alternateRegions}
        slug={props.pageContext.slug}
      />

      <LayOut>
        <Hero heroContent={featuressHero} featuredHero />
        <Container
          margin="50px auto"
          backgroundColor="babyBlue"
          style={{ display: "flex", justifyContent: "center" }}
          padding="50px 0"
        >
          <FeatureImageList imageList={featureImageList} />
        </Container>
        <Container
          margin="50px auto"
          backgroundColor="white"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <EnterpriseFeatureList featureList={enterpriseFeatureList} />
        </Container>
        <PlansAnchorContainer>
          {/* Do not change the id for this area from 'plans'. It is used in links from external sources. */}
          <PlansAnchor id="plans"></PlansAnchor>
        </PlansAnchorContainer>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            placeItems: "center",
          }}
          margin="auto"
          backgroundColor="babyBlue"
          padding="50px 0"
        >
          <h2 style={{ textAlign: "center" }}>Celo Plans</h2>
          <PricingLists sanityPricingLists={pricingLists} />
        </Container>
        <Cta
          showButtonInMobile
          content={ctaBlock}
          h2FontSize="4.4rem"
          h2LineHeight="4.4rem"
          isFeaturePage
        />
      </LayOut>
    </>
  );
};

export default Features;

export const query = graphql`
  query featureQuery($iban: String) {
    allSanityFeaturesPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        featuressHero {
          ...sanityHero
        }
        pricingLists {
          _key
          _type
          pricingButton {
            isInternal
            linkText
            linkUrl
            elementId
          }
          featureList
          listHeader
          description
          title
        }
        ctaBlock {
          ctaText {
            _rawContent
          }
          ctaImage {
            ...sanityImageWithMeta
          }
          ctaButton {
            linktext
            url
          }
        }
        seo {
          ...sanitySeo
        }
        featureImageList {
          _type
          _key
          description
          title
          imageCards {
            description
            image {
              altText
              asset {
                id
                url
                altText
              }
            }
            title
          }
        }
        enterpriseFeatureList {
          description
          title
          _type
          Button {
            isInternal
            linkText
            linkUrl
          }
          enterpriseFeatureList {
            link {
              internallink
              linktext
              url
              linkStyle
            }
            title
            description
            image {
              ...sanityImageWithMeta
            }
          }
        }
      }
    }
    altRegions: allSanityFeaturesPage {
      nodes {
        featuressHero {
          ...sanityHero
        }
        pricingLists {
          _key
          _type
          pricingButton {
            isInternal
            linkText
            linkUrl
          }
          featureList
          listHeader
          description
          title
        }
        ctaBlock {
          ctaText {
            _rawContent
          }
          ctaImage {
            ...sanityImageWithMeta
          }
          ctaButton {
            linktext
            url
          }
        }
        seo {
          ...sanitySeo
        }
        featureImageList {
          _type
          _key
          description
          title
          imageCards {
            description
            image {
              altText
              asset {
                id
                url
                altText
              }
            }
            title
          }
        }
        enterpriseFeatureList {
          description
          title
          _type
          Button {
            isInternal
            linkText
            linkUrl
          }
          enterpriseFeatureList {
            link {
              internallink
              linktext
              url
              linkStyle
            }
            title
            description
          }
        }
      }
    }
  }
`;
